
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class Card extends Vue {

    @Prop()
    public readonly header!: string | undefined;

}
