
import 'fast-text-encoding';
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import * as QrCodeLib from "qrcode"

@Component({})
export default class QrCode extends Vue {
    @Prop({required: true})
    public text!: string;

    private mounted() {
        this.renderQRCode();
    }

    private get canvas(): HTMLCanvasElement {
        return this.$refs.renderTarget as HTMLCanvasElement;
    }

    private renderQRCode() {
        try {
            QrCodeLib.toCanvas(this.canvas, this.text, (error) => {
                if (error) {
                    alert("QR Code rendering failed: 1")
                    alert(JSON.stringify(error));
                    console.error("QR Code rendering failed:", error);
                }
            });
        } catch (error) {
            alert("Error in QR code generation: 2")
            alert(JSON.stringify(error))
            console.error("Error in QR code generation:", error);
        }
    }
}

